<template>
  <div class="materialsApply">
    <div class="content">
      <!-- 顶部 -->
      <Header :titleName="'物资申领'" :return="true" />
      <!-- 搜索 -->
      <div class="banner">
        <img class="bgimg" :src="data.detail.imgUrl" alt="" v-if="data.detail.imgUrl">
        <img class="image_empty" v-else src="@/assets/images/materialsApply/image_empty.png" alt="">
        <div class="cont">
          <img class="red_bg" src="@/assets/images/materialsApply/red_bg.png" alt="">
          <div class="text" v-if="data.detail.startStatus == 3">项目已结束</div>
          <div class="text" v-else>距项目申领{{ data.detail.startStatus == 1 ? '开始还有' : '结束' }}<span>{{ filteredTime() }}</span>天
          </div>
          <div class="blue_bg_box">
            <img class="blue_bg" src="@/assets/images/materialsApply/blue_bg.png" alt="">
            <div class="blue_bg_text">{{ data.detail.startStatus == 1 ? '未开始' : data.detail.startStatus == 3 ? '已结束' :
              '进行中' }}
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="slmsg">
          <i class="f33 fw6" style="margin-bottom: .2rem;">{{ data.detail.title }}</i>
          <!--<div><span>慰问物资</span>{{ data.goodsDetail.goodsName }}</div>
          <div><span>物资数量</span>{{ data.detail.minQuantity }}份</div>-->
          <!-- <div><span>申领人数</span>{{ data.detail.receiveNumber }}人</div> -->
          <div><span>领取方式</span>{{ data.detail.applyWay }}</div>
          <div><span>开始时间</span>{{ data.detail.startTime }}</div>
          <div><span>结束时间</span>{{ data.detail.endTime }}</div>
        </div>
        <div style="margin-bottom: .4rem;">
          <div class=" classname space_between align_items_center">
            <div style="display: block;">
              项目详情
              <div class="line"></div>
            </div>
            <div class="justify_content_end align_items_center"
              style="font-size: .2933rem;color: #888;font-weight: 400;" @click="data.prject_content_zk = !data.prject_content_zk">
              {{ data.prject_content_zk ? '收起' : '查看详情' }}
              <img class="zk" src="@/assets/images/donate/zk.png" alt=""
                :style="data.prject_content_zk ? ' transform: rotateZ(180deg);' : ''">
            </div>
          </div>
          <div class="details_text prject_content" v-html="data.detail.content" v-show="data.prject_content_zk"></div>
        </div>


        <div v-if="data.detail.applyCondition">
          <div class=" classname ">
            <div style="display: block;">
              申领条件
              <div class="line"></div>
            </div>
          </div>
          <div class="details_text applyCondition" v-html="data.detail.applyCondition" :class="[data.prject_applyCondition_zk_show ? 'all_height' : '',data.prject_applyCondition_zk?'hidden':'']"></div>
          <div style="text-align: center;">
            <img class="zk" src="@/assets/images/materialsApply/zk.png" v-if="data.prject_applyCondition_zk"
              :style="data.prject_applyCondition_zk_show ? 'transform: rotate(180deg);' : ''"
              @click="data.prject_applyCondition_zk_show = !data.prject_applyCondition_zk_show" alt="">
          </div>
          
        </div>

        <div v-if="data.detail.applyProgram">
          <div class=" classname">
            <div style="display: block;">
              登记程序
              <div class="line"></div>
            </div>
          </div>
          <div class="details_text applyProgram" v-html="data.detail.applyProgram" :class="[data.prject_applyProgram_zk_show ? 'all_height' : '',data.prject_applyProgram_zk?'hidden':'']"></div>
          <div style="text-align: center;">
            <img class="zk" src="@/assets/images/materialsApply/zk.png" v-if="data.prject_applyProgram_zk"
              :style="data.prject_applyProgram_zk_show ? 'transform: rotate(180deg);' : ''"
              @click="data.prject_applyProgram_zk_show = !data.prject_applyProgram_zk_show" alt="">
          </div>
          
        </div>
 

      </div>

      <!-- <div style="height: .2667rem;background-color: #f5f5f5;"></div> -->


      <!-- <div class="card" style="padding-top: .3rem;">


        <div class=" classname">
          <div style="display: block;">
            物资详情
            <div class="line"></div>
          </div>
        </div>
        <div class="details_text" v-html="data.goodsDetail.goodsContent"></div>

      </div> -->

      <!-- <div class="service">
        <img src="@/assets/images/materialsApply/service.png" alt="">
        <img src="@/assets/images/materialsApply/share.png" alt="">
      </div> -->
      <div class="footer_btn">
        <div v-if="data.detail.startStatus == 1" class="slbtn_end">我要申领</div>
		<div v-else-if="data.detail.startStatus == 3" class="slbtn_end">申领结束</div>
		<div v-else @click="applyFn()">我要申领</div>
      </div>



      <!-- 物资申领下载文件弹窗 -->
      <div class="jj_dialog" v-if="data.downFileShow" @click=" data.downFileShow = false">
        <div class="red_bg" @click.stop="">
          <div class="white_box">
            <img class="white_bg_img" src="@/assets/images/materialsApply/white_bg.png" alt="">
            <div class="white_cont" style="margin-bottom: 1rem;">
              <div>
                <div class="ti"></div>
                <img class="heart_img" src="@/assets/images/materialsApply/heart.png" alt="">
                <div class="name">{{ data.detail.title }}</div>
                <img style="width: 2.7467rem;margin-top: 1rem;" src="@/assets/images/materialsApply/down_file_img.png"
                  alt="" @click="downFile">
                <div style="font-weight: bolder;font-size: 16px;padding-top: 15px;color: #0087ff;" @click="downFile">
                  点击下载</div>
              </div>

              <div class="msg">
                <div style="margin-bottom: .1rem;">1. 请先下载文件</div>
                <div>2.仔细阅读完成申领</div>
              </div>
            </div>
          </div>
          <img class="white_close" src="@/assets/images/white_close.png" alt="" @click=" data.downFileShow = false">
        </div>
      </div>

      <div class="jj_dialog" v-if="data.dia" @click=" data.dia = false">
        <div class="red_bg" @click.stop="">
          <div class="white_box">
            <img class="white_bg_img" src="@/assets/images/materialsApply/white_bg.png" alt="">
            <div class="white_cont">
              <div class="ti">物资申领二维码</div>
              <img class="heart_img" src="@/assets/images/materialsApply/heart.png" alt="">
              <div class="name">{{ data.detail.title }}</div>
              <img class="ewm_img" id="ewm_img" :src="data.goodType3Data.qrCode" alt="">
              <div class="msg">
                <div style="margin-bottom: .1rem;">1. 保存二维码至相册</div>
                <div>2. 微信扫码申领物资</div>
              </div>
            </div>
          </div>
          <div class="btns" v-if="data.detail.url">
            <div @click="toLink">前往领取</div>
            <div @click="BtnLogo" class="active">保存至相册</div>
          </div>
          <div class="btns" v-else>
            <div style="width: 100%;" @click="BtnLogo" class="active">保存至相册</div>
          </div>
          <img class="white_close" src="@/assets/images/white_close.png" alt="" @click=" data.dia = false">
        </div>
      </div>





      <!-- //下载的canvas样式 -->
      <div class="jj_dialog" style="display: flex;left: 9999px;">
        <div class="red_bg" id="capture" style="height: 14rem;">
          <div class="white_box">
            <img class="white_bg_img" src="@/assets/images/materialsApply/white_bg.png" alt="">
            <div class="white_cont">
              <div class="ti">物资申领二维码</div>
              <img class="heart_img" src="@/assets/images/materialsApply/heart.png" alt="">
              <div class="name">{{ data.detail.title }}</div>
              <img class="ewm_img" :src="data.goodType3Data.qrCode" alt="">
              <div class="msg">
                <div style="margin-bottom: .1rem;">1. 保存二维码至相册</div>
                <div>2. 微信扫码申领物资</div>
              </div>
            </div>
          </div>

        </div>
      </div>
	  
	  <!-- 微信捐赠捐赠提示弹窗 -->
	  <div class="jj_dialog succeed" v-if="data.wechatTShow" @click="data.wechatTShow=false">
	    <div class="succeed_view" @click.stop="">
	      <img class="succeed_img" src="@/assets/images/donate/warn.png" alt="">
	      <div style="color: #222;margin:.4rem .6rem 0.2rem;text-align: left;">您好本项目仅可以在<b>{{data.detail.judgKey}}</b>平台领取。</div>
		  <div style="color: #222;margin:.2rem .6rem 0.6rem;text-align: left;" v-html="data.pingtaimsg"></div>
	      <div>
	         <div class="btn red_btn" @click="data.wechatTShow = false">关闭</div> 
	      </div>
	  
	    </div>
	  </div>
	  

    </div>

  </div>
</template>

<script setup>

import { reactive, onMounted, computed } from "vue";
import html2canvas from 'html2canvas'
import { useRoute, useRouter } from 'vue-router'
import Header from "../../components/Header.vue";
import Api from "../../utils/api";
import qrcode from 'qrcode'
import axios from "axios";
import { openAppFunction } from '../../common/js/share'


let data = reactive({
  dia: false,
  id: 0,
  code:"",
  guanzhu:0,
  pingtai:"",
  
  pingtaimsg:"",
  downFileShow: false,
  wechatTShow:false,
  detail: {},
  goodsDetail: {},
  goodType3Data: {},
  contentAllShow: false,
  prject_content_zk: false,
  prject_applyCondition_zk: false,
  prject_applyCondition_zk_show: false,
  prject_applyProgram_zk: false,
  prject_applyProgram_zk_show: false,
});

const route = useRoute()
const router = useRouter()
const filterUnsafeHtml = (str) => {
  var arrEntities = {
    'lt': '<',
    'gt': '>',
    'nbsp': ' ',
    'amp': '&',
    'quot': '"'
  };

  let newStr = str.replace(/&(lt|gt|nbsp|amp|quot);/ig,
    function (all, t) {
      return arrEntities[t];
    });
  

  //判断是不是空文本
  function replaceText(val) {
    if (val != null && val != "") {
      var msg = val.replace(/<(?!img\b)[^>]+>/gi,""); //执行替换成空字符
      msg = msg.replace(/\s/g,""); //去掉所有的空格（中文空格、英文空格都会被替换）
      msg = msg.replace(/[\r\n]/g,""); //去掉所有的换行符
      return msg
    } else return ''
  }
  
  return replaceText(newStr) ? newStr : ""
}


const checkAPP = (str) => {
	
	if(str!="" && str!=undefined && str.toLowerCase().indexOf("全平台")>-1){
		return;
	}
	
	data.pingtai="MOBILE";
	let userAgent=navigator.userAgent.toLowerCase();
	if(/micromessenger/.test(userAgent)){
		data.pingtai="公众号";
	}
	if(/qzapp/.test(userAgent)){
		data.pingtai="职工之家";
	}
	
	if(str!="" && str!=undefined && str.toLowerCase().indexOf(data.pingtai)==-1){
		
		let errIndex=1;
		if(str.toLowerCase().indexOf("公众号")>-1){
			data.pingtaimsg+="提示：搜索并关注公众号“中国职工发展基金会”，领取。<br/>";
			errIndex++;
		}
		if(str.toLowerCase().indexOf("职工之家")>-1){
			data.pingtaimsg+="提示：应用商店搜索“职工之家”App，进入爱心互献模块领取。<br/>"
		}
		
		data.wechatTShow=true;
		return;
	}
	
	
	if(str!="" && str!=undefined && str.toLowerCase().indexOf(data.pingtai)>-1 && data.pingtai=="公众号"){
	  
	  let appid = "wxd176b80ea0e67478";
	  //const redirect_uri = "https%3A%2F%2Fwww.china-wdf.org.cn%2Fh5%2Fdonation";
	  //console.log(window.location.href)
	  let redirect_uri = encodeURIComponent(window.location.href);
	  let response_type = "code";
	  let scope = "snsapi_userinfo";
	  let state = "STATE";
	  let weiXinInf="weiXinInf";
	  data.code=new URLSearchParams(window.location.search).get('code');
	  
		if (redirect_uri.indexOf('?')) {
		  redirect_uri += ('&v=' + new Date().getTime());
		}
		
	  if(sessionStorage.getItem(weiXinInf)!=null){
		  let wxJson=JSON.parse(sessionStorage.getItem(weiXinInf));
		  
		  Api.checkGZ(wxJson).then(res=>{
			  //console.log(res);
			  if (res!=undefined && res.status == 200) {
			  	if(res.data.gz==0){
					data.pingtaimsg="提示：搜索并关注公众号“中国职工发展基金会”，领取。<br/>";
					data.wechatTShow=true;
				}else{
					data.guanzhu=1;
				}
			  }
		  });
		  return;
	  }
	    
	  if (!data.code) {
	    if (/micromessenger/.test(navigator.userAgent.toLowerCase())) {
	      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}&state=${state}#wechat_redirect`;
	    }
	    //console.log("第一次进来没有code", data.code);
	  } else {
	    
	    console.log("mount", data.code);
		let parm={siteId:1,provider:"wechat",code:data.code}
	    Api.getOpenIdHandler(parm).then(res=>{
			//console.log(res)
			if (res!=undefined && res.status == 200) {
				let oparm={openid:res.data.openId,access_token:res.data.access_token};
				sessionStorage.setItem(weiXinInf,JSON.stringify(oparm) );
				Api.checkGZ(oparm).then(res=>{
				  if (res!=undefined && res.status == 200) {
				  	if(res.data.gz==0){
						data.wechatTShow=true;
					}else{
						data.guanzhu=1;
					}
				  }
				});
			}
			
		});
	    console.log("获取到code", data.code);
	  }
	  console.log(route);
  }

}

onMounted(() => {

  data.id = route.query.id
  console.log(data.id)
  Api.applyForProjectDetails(data.id).then(res => {
    if (res.status == 200) {
      data.detail = res.data
      data.goodsDetail = res.data.applyForProjectDetails[0]
      data.detail.content = data.detail.content ? filterUnsafeHtml(data.detail.content) : data.detail.content
      data.goodsDetail.goodsContent = data.goodsDetail.goodsContent ? filterUnsafeHtml(data.goodsDetail.goodsContent) : data.goodsDetail.goodsContent
      // if (data.goodsDetail.goodsContent) setFontSize('prject_content')
      if (data.detail.applyCondition) setFontSize('applyCondition')
      if (data.detail.applyProgram) setFontSize('applyProgram')

      data.detail.applyCondition = data.detail.applyCondition ? filterUnsafeHtml(data.detail.applyCondition) : data.detail.applyCondition
      data.detail.applyProgram = data.detail.applyProgram ? filterUnsafeHtml(data.detail.applyProgram) : data.detail.applyProgram
      data.prject_content_zk = !data.detail.applyProgram&&!data.detail.applyCondition?true:false
      console.log(data)
	  checkAPP(data.detail.judgKey);
      shareFn()
    }
  })
});


const shareFn = () => {
  const jsonString = {
    shareUrl:window.location.href,//分享链接
    shareCorver: data.detail.imgUrl,
    shareTitle: '中国职工发展基金会',
    shareDesc: data.detail.title ,//分享描述
  }
  console.log(jsonString)
  openAppFunction('setupShareData', { shareData: JSON.stringify(jsonString) })
}
function setFontSize(className) {
  setTimeout(() => {
    let yy = document.querySelector('.' + className)
    console.log(yy.scrollHeight, yy.clientHeight)
    if (yy.scrollHeight > yy.clientHeight) {
      if (className == 'prject_content') {
        data.prject_content_zk = true
      }
      if (className == 'applyCondition') {
        //onsole.log(1111111)
        data.prject_applyCondition_zk = true
        data.prject_applyCondition_zk_show = false
      }
      if (className == 'applyProgram') {
        data.prject_applyProgram_zk = true
        data.prject_applyProgram_zk_show = false
      }
    }
  }, 100);
}


const downFile = () => {
  window.location.href = data.detail.url
}

// const getqrcode = () => {
//   // let qrNumber = qrurl ? qrurl : '11111'
//   qrcode.toDataURL(data.detail.url, (err, url) => {
//     if (err) {
//       console.error(err);
//     } else {
//       data.goodType3Data.qrCode = url
//       data.dia = true
//     }
//   })
// }
const applyFn = () => {

  //router.push({ path: '/applyMessage', query: { id: data.id } })
  
  let judgKey=data.detail.judgKey?.toLowerCase();
  if(judgKey.length>0 && judgKey.indexOf("全平台")==-1){
	  
	  if(judgKey.indexOf("公众号")>-1 && data.pingtai!="公众号"){
		
	  	data.wechatTShow=true;
	  	return;
	  }
	  if(judgKey.indexOf("公众号")>-1 && data.pingtai=="公众号" && data.guanzhu!=1){
		data.pingtaimsg="提示：搜索并关注公众号“中国职工发展基金会”，领取。<br/>";
	  	data.wechatTShow=true;
	  	return;
	  }
	 
	  if(judgKey.indexOf(data.pingtai)==-1){
	  	  data.wechatTShow=true;
	  	  return;
	  }
	  
  }
  

  if (data.detail.goodType == 3) {
    if (data.detail.qrCode) {
      data.goodType3Data.qrCode = data.detail.qrCode
      data.dia = true

    }
    if (data.detail.url) {
      // data.dia = true
      window.location.href = data.detail.url
    }

  } else if (data.detail.goodType == 4) {
    //console.log(11111)
    data.downFileShow = true
    // window.location.href = data.detail.url
  } else {
    router.push({ path: '/applyMessage', query: { id: data.id } })
  }
}



const filteredTime = computed(() => () => {
  let item = data.detail
  let day = 0
  if (item.startStatus == 1) {
    day = Math.ceil(Math.abs((new Date().getTime() - new Date(item.startTime).getTime()) / 86400000))
  }
  if (item.startStatus == 2) {
    day = Math.ceil((new Date(item.endTime).getTime() - new Date().getTime()) / 86400000)
  }

  return Math.abs(day); 

})

function exportImage(base64str, fileName) {
  // 将base64格式的图片转换成Blob对象
  var arr = base64str.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  // 将Blob对象转换成文件并下载到本地
  var blob = new Blob([u8arr], {
    type: mime
  });
  var a = document.createElement('a');
  a.download = fileName;
  a.href = URL.createObjectURL(blob);
  a.click();
}





const BtnLogo = () => {
  // console.log('上传logo')
  let dpi = window.devicePixelRatio || 2
  let options = {
    useCORS: true, //跨域
    ignoreElements: false,
    allowTaint: true,
    scale: dpi,
  }
  html2canvas(document.querySelector('#capture'), options).then(canvas => {
    let url = canvas.toDataURL('image/png') //转base64
    // document.querySelector('.captures').innerHTML = `<img src="${url}" alt="231231">`
    //let domimg = document.querySelector('img') //复制-扩展知识
    exportImage(url, '扫我申领')
  })
}
// const onloadImg = (e) => {
// let y = document.getElementById('ewm_img')
// console.log(e)
// const img = new Image();
// const canvas = document.createElement('canvas');
// const ctx = canvas.getContext('2d');
// img.src = 'http://me.i5tool.com:12000/upload/202406/11/e25a25a1-a246-4356-991a-07e9899f7223.png'
// // 设置canvas的宽高与图片一致
// canvas.width = y.width;
// canvas.height = y.height;

// 在canvas上绘制图片
// ctx.drawImage(y, 0, 0);
// const dataURL = canvas.toDataURL('image/png');
// console.log(dataURL)
// data.goodType3Data.qrCode = canvas.toDataURL('image/png');

// }
</script>

<style lang='less' scoped>
.materialsApply {
  display: flex;
  flex-flow: column;
  height: 100%;

  .all_height {
    height: auto !important;

  }

  .hidden {
    overflow: hidden !important;
  }

  .applyCondition,.applyProgram{
    height: 2.5rem;
    overflow-y: scroll;
  }
  .details_text {
    

    /deep/img {
      max-width: 100% !important;
      display: block;
    }
  }

  .jj_dialog {
    .white_close {
      width: .8667rem;
      position: absolute;
      left: 0;
      bottom: -1.5rem;
      left: 0;
      right: 0;
      margin: auto;
    }

    .btns {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      padding: 0 .4rem;

      >div {
        width: 4.0533rem;
        height: 1.1333rem;
        background-color: #f5f5f5;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .3867rem;
        border-radius: .0533rem;
      }

      .active {
        background-color: #E61D18;
        color: #fff;
      }
    }

    .white_box {
      width: 8.5333rem;
      // height: 13.4133rem;
      margin-left: .4rem;
      margin-top: .4rem;
      position: relative;
      padding: .6rem 1.0267rem;

      .white_cont {
        position: relative;
        z-index: 2;
        text-align: center;
        padding-right: .15rem;
        display: flex;
        height: 11rem;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        // background-color: red;
        .ti {
          font-size: .6133rem;
          margin-bottom: .2rem;
        }

        .heart_img {
          width: 5.9333rem;
          margin-bottom: .2rem;
        }

        .name {
          font-size: .3333rem;
          margin-bottom: .2rem;
        }

        .ewm_img {
          width: 6.4rem;
          height: 6.4rem;
        }

        .msg {
          font-size: .4667rem;
          margin-top: .8rem;
        }
      }

      .white_bg_img {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .red_bg {
      width: 9.1067rem;
      background: linear-gradient(180deg, #E61D18 0%, #FEBFBF 21%, #FFFFFF 64%, #FFFFFF 100%);
      border-radius: .2533rem;
      position: absolute;
      left: 0;
      right: 0;
      top: 1rem;
      margin: auto;
      padding-bottom: .5rem;
    }
  }

  .zk {
    width: .4rem;
    transition: all .6s;
  }

  .content {
    flex: 1;
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;
    padding-bottom: 2.2667rem;
    background-color: #fff;
    .service {
      position: fixed;
      right: .1rem;
      bottom: 2.6667rem;

      img {
        width: 1.9467rem;
        display: block;
      }
    }
	
	.succeed {
	  .succeed_view {
	    width: 6.92rem;
	    padding: .6667rem 0;
	    background: #FFFFFF;
	    border-radius: .2533rem;
	    position: absolute;
	    left: 0;
	    right: 0;
	    top: 30vh;
	    margin: auto;
	    text-align: center;
	    display: flex;
	    flex-direction: column;
	    align-items: center;
	    justify-content: center;
	    font-size: .3867rem;
	    min-height: 6.4rem;
	
	    .btn {
	      width: 5.64rem;
	      height: 1.1333rem;
	      color: #333;
	      background-color: #F5F5F5;
	      border-radius: .1067rem;
	
	      display: flex;
	      justify-content: center;
	      align-items: center;
	      margin-bottom: .2667rem;
	    }
	
	    .red_btn {
	      background: #E61D18 !important;
	      color: #fff !important;
	    }
	
	    .succeed_img {
	      width: 1.4667rem;
	      margin-bottom: .4rem;
	
	    }
	  }
	}
	

    .footer_btn {
      height: 2.3333rem;
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 0;
      background-color: #fff;
      box-shadow: 0px -0.1333rem .08rem 1px rgba(0, 0, 0, 0.03);

      >div {
        width: 9.3867rem;
        height: 1.1333rem;
        background: #E61D18;
        border-radius: .1067rem;
        margin: .2667rem auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .3867rem;
        color: #fff;
      }
    }

    .card {
      background-color: #fff;
      padding: 0 .3067rem;
      padding-bottom: .4rem;
      margin-bottom: .2667rem;
      .details_text {
        font-size: .36rem;
        color: #666;
        line-height: .7rem;
      }

      .classname {
        margin-bottom: .2rem;
        color: #333;
        font-size: .44rem;
        font-weight: 600;

        >div {
          width: 1.8467rem;
        }

        .line {
          background: linear-gradient(240deg, rgba(255, 255, 255, 0) 0%, #E61D18 100%);
          margin-top: .1067rem;
          height: .1067rem;
          border-radius: 1.28rem;
          // margin-bottom: .2667rem;
        }

      }

      .slmsg {
        width: 9.3867rem;
        background: #FFFFFF;
        box-shadow: 0px .1333rem .1333rem 1px rgba(0, 0, 0, 0.05);
        border-radius: .0667rem 0px .2533rem .2533rem;
        padding: .2667rem .3067rem;
        padding-bottom: .5rem;
        margin-bottom: .5rem;

        i {
          font-style: normal;
          display: block;
          line-height: .6rem;
        }

        >div {
          line-height: .8rem;
          font-size: .36rem;
          margin-left: .0533rem;

          span {
            color: #888;
            margin-right: .5067rem;
          }
        }

        .f33 {
          font-size: .44rem;
        }
      }
    }
    .banner {
      width: 100%;
      height: 6.44rem;
      position: relative;
      background-color: #f5f5f5;
      .image_empty{
        width: 1.6rem;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
      .cont {
        width: 9.3867rem;
        height: 1.1333rem;
        position: absolute;
        bottom: 0;
        left: .3333rem;
        z-index: 2;

        .blue_bg_box {
          width: 1.4533rem;
          height: .4133rem;
          position: absolute;
          top: -.12rem;
          left: .4rem;
          font-size: .2533rem;

          .blue_bg_text {
            position: relative;
            color: #fff;
            margin-top: .05rem;
            text-align: center;
            z-index: 2;
          }

          .blue_bg {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
          }
        }

        .text {
          position: relative;
          font-size: .36rem;
          color: #fff;
          margin-top: .4rem;
          margin-left: .2667rem;
          z-index: 2;

          span {
            font-size: .4133rem;
            font-weight: 600;
            margin: 0 .16rem;
          }
        }

        .red_bg {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      .bgimg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background-color: #fff;
      }
    }

.slbtn_end {
          background-color: #ACACAC !important;
          color: #fff !important;
        }


  }
}
</style>